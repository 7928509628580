import {request} from '@/utils/request'

// 提交企业认证
export function postMerchant(data) {
  return request('POST','/shop/corporate', data, true, true)
}
// 获取企业认证
export function getMerchant(data) {
  return request('GET','/shop/corporate', data, true, true)
}
// 获取企业认证类目
export function getMerchantClass(data) {
  return request('POST','/shop/corporate/getCorporateToId3s', data, true, true)
}
// 编辑企业认证
export function putMerchant(data) {
  return request('PUT','/shop/corporate', data, true, true)
}
// 渠道协议列表
export function getChannelAgreementList(data) {
  return request('GET','/shop/corporate/getPaymentMasterCodeAndMerchantNo', data, true, true)
}
// 渠道协议信息
export function getChannelAgreementInfo(data) {
  return request('GET','shop/corporate/getAgreement', data, true, true)
}



