<template>
    <div class="admin_page_body" v-loading="isRequestLoading">
        <div class="legend_main">
            <el-steps :active="steps" process-status="finish" finish-status="success" simple>
                <el-step title="基本信息" icon="el-icon-info"></el-step>
                <el-step title="财务资质信息" icon="el-icon-s-finance"></el-step>
                <el-step title="店铺经营信息" icon="el-icon-s-shop"></el-step>
                <el-step title="提交审核成功" icon="el-icon-success"></el-step>
            </el-steps>

            <h1 class="title mt32">说明</h1>
            <p class="text"> · 请填写入驻企业信息，信息提交后请耐心等待平台审核。</p>
        </div>

        <merchantEditFirst v-show="steps === 0" @submit="submitFirst" :infoData="infoData" />
        <merchantEditSecond v-show="steps === 1" @submit="submitSecond" :infoData="infoData" :channelList="channelList" @changeStep="changeStep" />
        <merchantEditThird v-show="steps === 2" :classData="classData" :infoData="infoData" :merchantClassData="merchantClassData" :isSubmitLoading="isSubmitLoading" @submit="submitThird" @changeStep="changeStep" />
        <merchantEditFourth v-show="steps === 3" :userStatus="userStatus" :infoData="infoData" @changeStep="changeStep" />

    </div>
</template>

<script>
  import {getGoodsClassAll} from '../../../../api/admin/public'
  import {
    postMerchant,
    getMerchant,
    getMerchantClass,
    putMerchant,
    getChannelAgreementList
  } from '../../../../api/admin/merchant'
  import {getUserInfo} from "../../../../api/public";
  const merchantEditFirst = () => import('./first')
  const merchantEditSecond = () => import('./second')
  const merchantEditThird = () => import('./third')
  const merchantEditFourth = () => import('./fourth')
  export default {
    name: "adminMerchantEdit",
    data () {
      return {
        steps: 0,
        firstForm: {},
        secondForm: {},
        thirdForm: {},
        classData: [],
        merchantClassData: [],
        isRequestLoading: false,
        isSubmitLoading: false,
        infoData: {},
        userStatus: 0, // 1 可用-未审核 2 禁用 3 注销 4 审核中 5 审核失败 6 审核通过 7 再次提交
        channelList: []
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
    },
    mounted () {
      this.getUserInfo()
      this.getChannelAgreementList()
    },
    methods: {
      submitFirst (form) {
        this.$store.commit('changeScrollTop');
        this.firstForm = form
        this.steps = 1
      },
      submitSecond (form) {
        this.$store.commit('changeScrollTop');
        this.secondForm = form
        this.steps = 2
      },
      submitThird (form) {
        this.$store.commit('changeScrollTop');
        this.thirdForm = form
        if (this.userStatus === 1) {
          this.postMerchant()
        }
        if (this.userStatus === 5) {
          this.putMerchant()
        }
        if (this.userStatus === 6) {
          this.putMerchant()
        }
      },
      changeStep (e) {
        this.$store.commit('changeScrollTop');
        this.steps = e
      },
      getGoodsClassAll () {
        getGoodsClassAll().then(res => {
          if (res.code === 0) {
            this.classData = res.data
          }
        })
      },
      postMerchant () {
        this.isSubmitLoading = true
        postMerchant({
          data: {
            ...this.firstForm,
            ...this.secondForm,
            ...this.thirdForm,
          }
        }).then(res => {
          this.isSubmitLoading = false
          if (res.code === 0) {
            this.getUserInfo()
          }
        }).catch(() => {
          this.isSubmitLoading = false
        })
      },
      putMerchant () {
        this.isSubmitLoading = true
        putMerchant({
          data: {
            ...this.firstForm,
            ...this.secondForm,
            store: this.thirdForm
          }
        }).then(res => {
          this.isSubmitLoading = false
          if (res.code === 0) {
            this.getUserInfo()
          }
        }).catch(() => {
          this.isSubmitLoading = false
        })
      },
      getUserInfo () {
        this.isRequestLoading = true
        getUserInfo().then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.userStatus = res.data.status
            if (this.userStatus === 1) {
              this.steps = 0
              this.getGoodsClassAll()
            }
            if (this.userStatus === 4 || this.userStatus === 5 || this.userStatus === 6 || this.userStatus === 7) {
              this.steps = 3
              if (this.userStatus === 5 || this.userStatus === 6) {
                this.getMerchant()
                this.getMerchantClass()
                this.getGoodsClassAll()
              }
            }
            this.$store.commit('changeUserStatus', this.userStatus)
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getMerchant () {
        getMerchant().then(res => {
          if (res.code === 0) {
            this.infoData = res.data
          }
        })
      },
      getMerchantClass () {
        getMerchantClass().then(res => {
          if (res.code === 0) {
            this.merchantClassData = res.data
          }
        })
      },
      getChannelAgreementList () {
        getChannelAgreementList({
          // corporateId: id
        }).then(res => {
          if (res.code === 0) {
            this.channelList = JSON.parse(res.data)
          }
        })
      }
    },
    components: {
      merchantEditFirst, merchantEditSecond, merchantEditThird, merchantEditFourth
    }
  }
</script>
